<template>
  <b-form
    @submit.prevent="$emit('processCuenta', usuario)"
    class="mt-3"
  >
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Password -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Contraseña"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="usuario.password"
              :state="!$v.usuario.password.$invalid"
              type="password"
              placeholder="Introduce la contraseña"
              @input="$v.usuario.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.usuario.$dirty"
              id="passwordInfo"
            >
              El campo contraseña
              <span v-if="!$v.usuario.password.required"> es requerido.</span>
              <span v-if="!$v.usuario.password.minLength"> debe tener al menos 6 caracteres.</span>
              <span v-if="!$v.usuario.password.maxLength"> debe tener max 30 caracteres.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Password -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Confirmar Contraseña"
            label-for="confirmarPassword"
          >
            <b-form-input
              id="confirmarPassword"
              v-model="usuario.confirmarPassword"
              :state="!$v.usuario.confirmarPassword.$invalid"
              type="password"
              placeholder="Introduce la contraseña"
              @input="$v.usuario.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.usuario.$dirty"
              id="confirmarPasswordInfo"
            >
              El campo confirmar contraseña
              <span v-if="!$v.usuario.confirmarPassword.required"> es requerido.</span>
              <span v-if="!$v.usuario.confirmarPassword.minLength"> debe tener al menos 6 caracteres.</span>
              <span v-if="!$v.usuario.confirmarPassword.maxLength"> debe tener max 30 caracteres.</span>
              <span v-if="!$v.usuario.confirmarPassword.sameAs"> debe coincidir con el campo contraseña.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <!-- <permisosForm
      v-if="permisos"
      :permisosUsuario.sync="usuario.permissions"
      :permissionsUsuario.sync="usuario.permissionsUsuario"
    /> -->

    <!-- Action Buttons -->
    <btnSubmit
      :btnDisabled="$v.usuario.$invalid"
      :btnText="usuarioSubmit"
    />
  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { validationMixin } from 'vuelidate'
import {
  required, minLength, maxLength, sameAs,
} from 'vuelidate/lib/validators'
import { formatearTelefono } from '@/mixins/forms'

const btnSubmit = () => import('@/layouts/components/Recycled/Form/btnSubmit.vue')

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    btnSubmit,
  },
  mixins: [validationMixin, formatearTelefono],
  props: {
    usuario: {
      type: Object,
      required: true,
    },
    usuarioSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Usuario',
    },
    validarPassword: {
      type: Boolean,
      default: true, // Texto del boton
    },
    configuracion: {
      type: Boolean,
      default: false, // Texto del boton
    },
  },
  validations() {
    return {
      usuario: {
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(30),
        },
        confirmarPassword: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(30),
          sameAsPassword: sameAs('password'),
        },
      },
    }
  },
  setup() {
    const rolOptions = [
      { value: null, text: 'Selecciona un rol' },
      { value: 'Admin', text: 'Admin' },
      { value: 'Bodeguero', text: 'Bodeguero' },
      { value: 'Vendedor', text: 'Vendedor' },
      { value: 'Ingeniero', text: 'Ingeniero' },
    ]

    const estadoOptions = [
      { value: null, text: 'Selecciona un estado' },
      { value: 'Activo', text: 'Activo' },
      { value: 'Inactivo', text: 'Inactivo' },
    ]

    // ? Demo Purpose => Update image on click of update
    const previewEl = ref(null)

    return {
      previewEl,
      rolOptions,
      estadoOptions,
    }
  },
  methods: {
    onFileSelected(e) {
      // Obtiene primera Imagen
      const file = e.target.files[0]
      this.cargarImg(file)
    },
    cargarImg(file) {
      // Objeto que permite trabajar con archivos
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.usuario.imagen = e.target.result
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
